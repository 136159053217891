import get from "lodash/get";
import { getOptionLabel } from "./common";
import { getOrderStatusOptions } from "./options";
import { ORDER_STATUS } from "../config/global";

const presentIncidentDetailStatus = (incidentDetail, i18n) => {
  const status = get(incidentDetail, "status");
  // stato
  if (
    [
      ORDER_STATUS.CREATE,
      ORDER_STATUS.PAYMENT_WAIT,
      ORDER_STATUS.APPROVAL,
      ORDER_STATUS.CANCELED,
      ORDER_STATUS.ALERT,
    ].includes(status)
  ) {
    const options = getOrderStatusOptions();
    return getOptionLabel(status, options);
  }
  // dal produttore
  if ([ORDER_STATUS.PRODUCER_IN_CHARGE].includes(status)) {
    return i18n.t('incident.modal.status.producer')+`<br/> ${get(
      incidentDetail,
      //"ddmp.producer.role.name"
      "ppm.lot.productproducer.producer.role.name"
    )}`;
  }

  // presso il mercato
  if (
    [
      ORDER_STATUS.MARKET_ARRIVED,
      ORDER_STATUS.MARKET_STOCK,
      ORDER_STATUS.MARKET_IN_CHARGE,
    ].includes(status)
  ) {
    return i18n.t('incident.modal.status.market');
  }
  // presso nome isola
  if (
    [ORDER_STATUS.ISLAND_ARRIVED, ORDER_STATUS.ISLAND_IN_CHARGE].includes(
      status
    )
  ) {
    return i18n.t('incident.modal.status.island')+`<br/> ${get(
      incidentDetail,
      "order.ddmi.island.role.name"
    )}`;
  }
  // consegnato: nome famiglia
  if (
    [ORDER_STATUS.FAMILY_IN_CHARGE, ORDER_STATUS.DELIVERED].includes(status)
  ) {
    return i18n.t('incident.modal.status.delivered')+`: ${get(incidentDetail, "ddmp.producer.role.name")}`;
  }

  return "";
};

const round = (number) => {
  return Math.round(number * 100) / 100;
};

const calculatePercentage = (actual, after) => {
  return round(((actual - after) / actual) * 100);
};

const calculateDiscounted = (price, perc) => {
  return round(price - (price * perc) / 100);
};

export {
  presentIncidentDetailStatus,
  round,
  calculatePercentage,
  calculateDiscounted,
};
